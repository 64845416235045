import React, { useState, useCallback, useRef, useEffect } from 'react';


const KnifeDogWebsite = () => {
  const [score, setScore] = useState(0);
  const [throws, setThrows] = useState(0);
  const [lastHit, setLastHit] = useState(null);
  const [knives, setKnives] = useState([]);
  const [isAnimating, setIsAnimating] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const targetRef = useRef(null);
  const initialKnifeRef = useRef(null);
  const [dartboardPosition, setDartboardPosition] = useState(0);
const animationRef = useRef(null);


useEffect(() => {
    let startTime;
    const animate = (time) => {
      if (startTime === undefined) {
        startTime = time;
      }
      const elapsed = time - startTime;
      const newPosition = Math.sin(elapsed * 0.002) * 50; // Adjust speed and range here
      setDartboardPosition(newPosition);
      animationRef.current = requestAnimationFrame(animate);
    };
    animationRef.current = requestAnimationFrame(animate);
  
    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, []);
  



  const throwKnife = useCallback((e) => {
    e.preventDefault(); // Prevent default to avoid screen pop-up
    if (throws < 5 && !isAnimating && targetRef.current && initialKnifeRef.current) {
      setIsAnimating(true);
      const targetRect = targetRef.current.getBoundingClientRect();
      const knifeRect = initialKnifeRef.current.getBoundingClientRect();
      const x = e.clientX - targetRect.left - dartboardPosition; // Adjust for dartboard movement

      const y = e.clientY - targetRect.top;

      const centerX = targetRect.width / 2;
      const centerY = targetRect.height / 2;
      const distance = Math.sqrt(Math.pow(x - centerX, 2) + Math.pow(y - centerY, 2));
      let points = 0;
      let hitArea = '';

      if (distance < 30) {
        points = 50;
        hitArea = 'Bullseye!';
      } else if (distance < 60) {
        points = 25;
        hitArea = 'Inner ring';
      } else if (distance < 90) {
        points = 10;
        hitArea = 'Outer ring';
      } else {
        hitArea = 'Missed';
      }

      setScore(prevScore => prevScore + points);
      setThrows(prevThrows => prevThrows + 1);
      setLastHit(hitArea);

      const newKnife = { 
        startX: knifeRect.left - targetRect.left + knifeRect.width / 2, 
        startY: knifeRect.bottom - targetRect.top,
        endX: x - 15, // Adjust for the width of the SVG
        endY: y - 45, // Adjust for the height of the SVG
        rotation: Math.random() * 360, 
        throwing: true 
      };
      setKnives(prevKnives => [...prevKnives, newKnife]);

      document.documentElement.style.setProperty('--startX', `${newKnife.startX}px`);
      document.documentElement.style.setProperty('--startY', `${newKnife.startY}px`);
      document.documentElement.style.setProperty('--endX', `${newKnife.endX - newKnife.startX + dartboardPosition}px`);

      document.documentElement.style.setProperty('--endY', `${newKnife.endY - newKnife.startY}px`);

      setTimeout(() => {
        setKnives(prevKnives => prevKnives.map((knife, index) => 
          index === prevKnives.length - 1 ? {...knife, throwing: false} : knife
        ));
        setIsAnimating(false);
        if (throws === 4) {
          setShowModal(true);
        }
      }, 1000);
    }
}, [throws, isAnimating, dartboardPosition]);


  const resetGame = useCallback(() => {
    setScore(0);
    setThrows(0);
    setLastHit(null);
    setKnives([]);
    setShowModal(false);
  }, []);

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      {/* Hero Section */}
<section className="relative h-screen flex items-center justify-center">
  <img 
    src={`${process.env.PUBLIC_URL}/photo1719250460.jpeg`}
    alt="Hero background" 
    className="absolute inset-0 w-full h-full object-cover opacity-70"
  />
  <nav className="absolute top-0 left-0 right-0 p-4 flex justify-between items-center">
    <div className="text-white text-2xl font-bold">$knife</div>
    <div className="flex space-x-4">
      <a 
        href="https://x.com/KnifeDogSol" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="text-white hover:text-blue-400 transition-colors"
      >
        Twitter
      </a>
      <a 
        href="https://t.me/knifedogsol" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="text-white hover:text-blue-400 transition-colors"
      >
        Telegram
      </a>
    </div>
  </nav>
</section>


      {/* Game Section */}
      <section className="py-16 bg-gray-800">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold mb-8 text-center">Play the $knifedog Challenge!</h2>
          <div className="flex flex-col items-center">
          <div 
  className="relative cursor-crosshair mb-8"
  ref={targetRef} 
  style={{ 
    width: '300px', 
    height: '300px',
    transform: `translateX(${dartboardPosition}px)`,
    transition: 'transform 0.1s linear'
  }}
  onClick={throwKnife}
>

              <svg width="300" height="300" viewBox="0 0 300 300">
                <circle cx="150" cy="150" r="140" fill="#ff0000" fillOpacity="0.8" />
                <circle cx="150" cy="150" r="93" fill="#ffffff" fillOpacity="0.8" />
                <circle cx="150" cy="150" r="47" fill="#ff0000" fillOpacity="0.8" />
              </svg>
              {knives.map((knife, index) => (
                <svg
                  key={index}
                  width="30"
                  height="90"
                  viewBox="0 0 20 60"
                  style={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    transform: `translate(${knife.throwing ? knife.startX : knife.endX}px, ${knife.throwing ? knife.startY : knife.endY}px) rotate(${knife.rotation}deg)`,
                    opacity: knife.throwing ? 0 : 1,
                    transition: knife.throwing ? 'none' : 'all 0.5s ease-out',
                    animation: knife.throwing ? 'throwKnife 1s forwards' : 'none'
                  }}
                >
                  <path
                    d="M10 0 L15 20 L15 55 L5 55 L5 20 Z"
                    fill="silver"
                    stroke="#000"
                    strokeWidth="1"
                  />
                  <rect x="5" y="55" width="10" height="5" fill="brown" />
                </svg>
              ))}
            </div>
            
            <div className="mb-8" ref={initialKnifeRef}>
              <img 
                src={`${process.env.PUBLIC_URL}/for_loyal_customer.png`}

                alt="Initial knife" 
                style={{ width: '100px', height: '120px' }}
              />
            </div>
            
            <div className="text-center mb-4">
              <p className="text-2xl mb-2">Score: {score}</p>
              <p className="text-xl mb-2">Throws: {throws}/5</p>
              {lastHit && <p className="text-lg mb-2">Last throw: {lastHit}</p>}
            </div>
          </div>
        </div>
      </section>

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg">
            <h2 className="text-xl font-bold mb-4 text-black">Game Over!</h2>
            <p className="mb-4 text-black">Your final score is {score} points.</p>
            <button 
              onClick={resetGame}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Play Again
            </button>
          </div>
        </div>
      )}
      
      <style jsx>{`
        @keyframes throwKnife {
          0% { 
            transform: translate(var(--startX), var(--startY)) rotate(0deg);
            opacity: 0;
          }
          1% {
            opacity: 1;
          }
          100% { 
            transform: translate(var(--endX), var(--endY)) rotate(720deg);
            opacity: 1;
          }
        }
      `}</style>
      <style jsx>{`
        :root {
          --startX: 0px;
          --startY: 0px;
          --endX: 0px;
          --endY: 0px;
        }
      `}</style>
    </div>
  );
};

export default KnifeDogWebsite;